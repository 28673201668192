import { DutyPackageName } from '~/types/graphql-global-types';
import partnerLogoFiles from '../../public/img/partners';
import { PartnerKey } from './constants';

// Moved this out of constants.ts to reduce initial chunk size: all partner images were included

export type PartnerData = {
  key: PartnerKey;
  name: string;
  logo: string;
  hide?: boolean;
  logoHeight?: string;
  logoWidth?: string; // Used by PartnerSlider
  dutyPackageName?: DutyPackageName | string;
  officeId?: string; // If we want to add customer requests to Samen
};

// Importing all partner logos is bad for bundle size
// I've already moved this outside of the common constants file, so it's not too bad now
export const DEB_PARTNERS: PartnerData[] = [
  {
    key: PartnerKey.DAK,
    name: 'DAK',
    logo: partnerLogoFiles.DAK,
    logoHeight: '2.5em',
    logoWidth: '120px',
    dutyPackageName: 'dakComplete',
  },
  {
    key: PartnerKey.BudgetThuis,
    name: 'Budget Thuis',
    logo: partnerLogoFiles.BudgetThuis,
    logoHeight: '2.5em',
    logoWidth: '120px',
    dutyPackageName: DutyPackageName.budgetThuisComplete,
  },
  {
    key: PartnerKey.EBP,
    name: 'Energie Bespaar Partner',
    logo: partnerLogoFiles.EBP,
    logoWidth: '120px',
  },
  {
    key: PartnerKey.FinancieelFit,
    name: 'FinancieelFit',
    logo: partnerLogoFiles.FinancieelFit,
    logoWidth: '150px',
    dutyPackageName: 'financieelFitPlan',
  },
  {
    key: PartnerKey.FinancieelZeker,
    name: 'FinancieelZeker',
    logo: partnerLogoFiles.FinancieelZeker,
    dutyPackageName: 'financieelZekerCompleet',
  },
  {
    key: PartnerKey.Florius,
    name: 'Florius',
    logo: partnerLogoFiles.Florius,
    logoWidth: '150px',
    dutyPackageName: DutyPackageName.florius,
  },
  {
    key: PartnerKey.GavoorA,
    name: 'GavoorA',
    logo: partnerLogoFiles.GavoorA,
    logoWidth: '120px',
    logoHeight: '90px',
    dutyPackageName: DutyPackageName.gavoora,
  },
  {
    key: PartnerKey.HypotheekBerekenen,
    name: 'Hypotheek Berekenen',
    logo: partnerLogoFiles.HypotheekBerekenen,
    logoWidth: '150px',
    logoHeight: '90px',
  },
  {
    key: PartnerKey.Hypotheekshop,
    name: 'De Hypotheekshop',
    logo: partnerLogoFiles.Hypotheekshop,
    logoWidth: '120px',
    dutyPackageName: DutyPackageName.CMISComplete,
  },
  {
    key: PartnerKey.Hypokeur,
    name: 'Hypokeur',
    logo: partnerLogoFiles.Hypokeur,
    logoWidth: '150px',
  },
  {
    key: PartnerKey.HuisHypotheek,
    name: 'Huis & Hypotheek',
    logo: partnerLogoFiles.HuisHypotheek,
    logoWidth: '150px',
    dutyPackageName: DutyPackageName.CMISComplete,
  },
  {
    key: PartnerKey.ImpactHypotheken,
    name: 'Impact Hypotheken',
    logo: partnerLogoFiles.ImpactHypotheken,
    logoWidth: '150px',
    dutyPackageName: 'impact',
  },
  {
    key: PartnerKey.Lot,
    name: 'Lot Hypotheken',
    logo: partnerLogoFiles.Lot,
    logoWidth: '120px',
    dutyPackageName: DutyPackageName.lotHypotheken,
  },
  {
    key: PartnerKey.MUNT,
    name: 'MUNT Hypotheken',
    logo: partnerLogoFiles.MUNT,
    logoWidth: '120px',
    dutyPackageName: DutyPackageName.muntHypotheken,
  },
  {
    key: PartnerKey.NBG,
    name: 'NBG',
    logo: partnerLogoFiles.NBG,
    logoWidth: '120px',
  },
  {
    key: PartnerKey.Triodos,
    name: 'Triodos Bank',
    logo: partnerLogoFiles.Triodos,
    logoWidth: '180px',
    dutyPackageName: DutyPackageName.triodos,
  },
  {
    key: PartnerKey.VanBruggen,
    name: 'Van Bruggen Adviesgroep',
    logo: partnerLogoFiles.VanBruggen,
    logoWidth: '150px',
    dutyPackageName: 'vanBruggenComplete',
  },
  {
    key: PartnerKey.VanLanschotKempen,
    name: 'Van Lanschot Kempen',
    logo: partnerLogoFiles.VanLanschotKempen,
    logoWidth: '180px',
    logoHeight: '90px',
    dutyPackageName: 'vanLanschotKempenComplete',
    hide: true,
  },
  {
    key: PartnerKey.Veldsink,
    name: 'Veldsink Advies',
    logo: partnerLogoFiles.Veldsink,
    logoWidth: '180px',
    officeId: '',
    dutyPackageName: DutyPackageName.veldsinkComplete,
  },
  {
    key: PartnerKey.Viisi,
    name: 'Viisi',
    logo: partnerLogoFiles.Viisi,
    logoWidth: '150px',
    dutyPackageName: DutyPackageName.viisiSimple,
  },
  {
    key: PartnerKey.HpHypotheken,
    name: '100% Hypotheken',
    logo: partnerLogoFiles.HPHypotheken,
    logoWidth: '150px',
    dutyPackageName: 'hpHypothekenSimplePlus',
  },
  {
    key: PartnerKey.ASR,
    name: 'a.s.r.',
    logo: partnerLogoFiles.ASR,
    logoWidth: '150px',
    dutyPackageName: 'asrComplete',
  },
  {
    key: PartnerKey.Frits,
    name: 'Frits',
    logo: partnerLogoFiles.Frits,
    logoWidth: '150px',
    dutyPackageName: 'fritsComplete',
    officeId: '634eaf16093fbe4903073256',
  },
  {
    key: PartnerKey.HypotheekVisie,
    name: 'Hypotheek Visie',
    logo: partnerLogoFiles.HypotheekVisie,
    logoWidth: '180px',
  },
  {
    key: PartnerKey.Ennatuurlijk,
    name: 'Ennatuurlijk',
    logo: partnerLogoFiles.Ennatuurlijk,
    logoWidth: '150px',
    dutyPackageName: 'ennatuurlijk',
  },
  {
    key: PartnerKey.GroeneHart,
    name: 'Groene Hart Hypotheken',
    logo: partnerLogoFiles.GroeneHart,
    logoWidth: '180px',
    dutyPackageName: 'groeneHartComplete',
  },
  {
    key: PartnerKey.Venn,
    name: 'Venn Hypotheken',
    logo: partnerLogoFiles.Venn,
    logoWidth: '150px',
    dutyPackageName: 'vennComplete',
  },
  {
    key: PartnerKey.Hypotheek24,
    name: 'Hypotheek24',
    logo: partnerLogoFiles.Hypotheek24,
    logoWidth: '150px',
  },
  {
    key: PartnerKey.Vista,
    name: 'Vista Hypotheken',
    logo: partnerLogoFiles.Vista,
    logoWidth: '180px',
    dutyPackageName: 'vistaComplete',
    officeId: '6494147c3fbaadfc7507e4aa',
  },
  {
    key: PartnerKey.EasySwitch,
    name: 'EasySwitch.nl',
    logo: partnerLogoFiles.EasySwitchKizi,
    logoWidth: '180px',
    dutyPackageName: 'easySwitchComplete',
    hide: true,
  },
  {
    key: PartnerKey.Overstappen,
    name: 'Overstappen',
    logo: partnerLogoFiles.OverstappenKizi,
    logoWidth: '180px',
    dutyPackageName: 'overstappenComplete',
    hide: true,
  },
  {
    key: PartnerKey.Consumentenbond,
    name: 'Consumentenbond',
    logo: '',
    dutyPackageName: 'consumentenbondComplete',
    officeId: '646cb5ea449c3cafaf61459e',
    hide: true,
  },
  {
    key: PartnerKey.Verbrugge,
    name: 'Verbrugge',
    logo: partnerLogoFiles.Verbrugge,
    logoWidth: '180px',
    dutyPackageName: 'verbruggeComplete',
  },
];

// Transform DEB_PARTNERS into a Record<PartnerKey, PartnerData>
export const DEB_PARTNERS_BY_KEY = DEB_PARTNERS.reduce(
  (acc, partner) => ({ ...acc, [partner.key]: partner }),
  {} as Record<PartnerKey, PartnerData>,
);
