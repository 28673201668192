import { gql, useLazyQuery } from '@apollo/client';
import { Box, Flex, Image, Rating } from '@energiebespaarders/symbols';
import { Larger } from '@energiebespaarders/symbols/helpers';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useIsInitializing } from '~/hooks/useIsInitializing';
import type { getKlantenVertellenScore } from '../../types/generated/getKlantenVertellenScore';
import delimit from '../../utils/delimit';
import KVBackground from './klantenvertellen.svg';
import Logo from '/public/img/logo_WEB.svg';

export const GET_KLANTENVERTELLEN_REVIEWS = gql`
  query getKlantenVertellenScore {
    getKlantenVertellenScore {
      averageRating
      numberReviews
      last12MonthAverageRating
      last12MonthNumberReviews
      percentageRecommendation
    }
  }
`;

const StyledWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  display: inline-block;
  width: 100px;
  height: 100px;
`;

const StyledBackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
`;

const StyledInt = styled(Box)`
  position: relative;
  font-size: 1.75rem;
  font-weight: 500;
`;

interface KlantenVertellenProps {
  layout?: 'vertical' | 'horizontal';
  scale?: number;
  transparent?: boolean;
}

const KlantenVertellen: React.FC<KlantenVertellenProps> = ({ layout = 'vertical', scale = 1 }) => {
  const [fetchScore, { data, loading, error }] = useLazyQuery<getKlantenVertellenScore>(
    GET_KLANTENVERTELLEN_REVIEWS,
    { fetchPolicy: 'cache-first' },
  );
  const ratingValue = data?.getKlantenVertellenScore?.last12MonthAverageRating ?? 9;
  const ratingCount = data?.getKlantenVertellenScore?.numberReviews ?? 100;

  // Can't query anything before app is initialized: another ME may get initialized
  const { isInitializing } = useIsInitializing();

  useEffect(() => {
    if (!data && !loading && !error && !isInitializing) void fetchScore();
  }, [fetchScore, data, loading, error, isInitializing]);

  return (
    <a
      title="Lees onze reviews op KlantenVertellen.nl"
      href="https://www.klantenvertellen.nl/reviews/1039761/de_energiebespaarders"
      target="_blank"
      rel="noopener noreferrer"
      className="image-link"
      style={{
        display: 'inline-block',
        color: 'black',
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
      }}
    >
      <Flex
        itemScope
        itemProp="aggregateRating"
        itemType="https://schema.org/AggregateRating"
        style={{ display: 'inline-flex' }}
        flexWrap={layout === 'vertical' ? 'wrap' : 'nowrap'}
      >
        <Box width={layout === 'vertical' ? 1 : undefined} style={{ textAlign: 'center' }}>
          <div
            itemScope
            itemProp="itemReviewed"
            itemType="https://schema.org/HomeAndConstructionBusiness"
          >
            <meta itemProp="name" content="Energiebespaarders" />
            <link itemProp="image" href={Logo} />
            <link itemProp="logo" href={Logo} />
            <link itemProp="url" href="https://energiebespaarders.nl" />
            <meta itemProp="telephone" content="0852103977" />
            <meta itemProp="priceRange" content="$$" />
            <meta itemProp="currenciesAccepted" content="EUR" />
            <time itemProp="openingHours" dateTime="Mo-Fr 09:00-17:30" />
            <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
              <meta itemProp="streetAddress" content="Reguliersdwarsstraat 58A" />
              <meta itemProp="postalCode" content="1017BM" />
              <meta itemProp="addressLocality" content="Amsterdam" />
              <meta itemProp="addressRegion" content="Noord-Holland" />
              <meta itemProp="addressCountry" content="Nederland" />
            </div>
          </div>
          <meta itemProp="ratingValue" content={`${ratingValue}`} />
          <meta itemProp="ratingCount" content={`${ratingCount}`} />
          <meta itemProp="bestRating" content="10" />
          <meta itemProp="worstRating" content="1" />

          <StyledWrapper>
            <StyledBackgroundImage src={KVBackground} alt="KlantenVertellen" />
            <Flex justifyContent="center" flexDirection="column" width="100%" height="100%">
              <StyledInt>{delimit(ratingValue, 1)}</StyledInt>
            </Flex>
          </StyledWrapper>
          {layout === 'vertical' && <Box mb={1}>{ratingCount} beoordelingen</Box>}

          <div style={{ transform: `scale(${scale})` }}>
            <Rating rating={ratingValue / 2} useHalfStars readOnly />
          </div>
        </Box>

        <Box width={layout === 'vertical' ? 1 : undefined}>
          <div>
            <Larger>
              klanten
              <strong>vertellen</strong>
            </Larger>
          </div>
          {layout === 'horizontal' && <Box mb={1}>{ratingCount} beoordelingen</Box>}
        </Box>
      </Flex>
    </a>
  );
};

export default KlantenVertellen;
